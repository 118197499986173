import { Atom } from ":mods";
import { TUTORS_FORM_STEPS } from "../../const";
import { createExperienceFirstPageInputs } from "../../form";
import { FormPageProps } from "../../model";
import { Show } from "solid-js";

export function ExperienceFirstPage({ onFormSubmit }: FormPageProps) {
  const FormInputs = createExperienceFirstPageInputs();
  const onSubmit = async (e: Event) => {
    e.preventDefault();
    if (!FormInputs.Actions.canSubmit()) return;
    const values = FormInputs.Actions.getValuesSnakecase();
    if (!values.cv) values.cv = null;
    // @ts-ignore
    else values.cv = values.cv[0] as unknown as File;
    onFormSubmit(values);
  };

  return (
    <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
      <Atom.CircleProgress.CircleProgressbar steps={TUTORS_FORM_STEPS} current_step={5} />

      <form class="flex flex-col items-left gap-20px text-16px w-630px">
        <h1 class="text-28px font-medium my-40px  ">
          Please tell us about your current profession and your experience in the field
        </h1>
        <p>
          We are looking for experienced museum industry professionals that can help to transfer knowledge and industry
          know how in the form of workshops, lectures and case studies.
        </p>
        <p>
          This is not an academic programme, but a hands on programme of workshops aimed at ecouraging Saudi citizens to
          consider careers within the museum industry and wider ecosystem.{" "}
        </p>
        <div class="flex flex-col gap-10px">
          <FormInputs.GoodFitDescription.Label
            title="Please describe why you think you will be a good fit?"
            class="mb-10px"
          >
            <FormInputs.GoodFitDescription.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.GoodFitDescription.Label>
          <div class="border-2 border#p border-solid w-630px">
            <FormInputs.GoodFitDescription.TextArea class="textarea-resize" placeholder="" />
          </div>
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.JobTitle.Label title="What is your current job title?" class="mb-10px">
            <FormInputs.JobTitle.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.JobTitle.Label>
          <FormInputs.JobTitle.Input
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.CV.Label title="Please upload a CV">
            <FormInputs.CV.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.CV.Label>

          <FormInputs.CV.InputFile
            accept=".pdf"
            classes={{
              container:
                "flex flex-col justify-center items-center bg-inherit border-2 border#p border-dashed text-14px px-2.5 py-50px w-630px",
            }}
            elements={{
              input({ onInputClicked, viewer_data, drag }) {
                return (
                  <div
                    onclick={onInputClicked}
                    class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                  >
                    <i class="icon-local:upload-icon w-29.5px h-19.6px" />
                    <p class="ms-2px cursor-pointer">
                      <Show when={!drag} fallback={"Drop Files"}>
                        Drag files here or <span class="underline">choose a file</span>
                      </Show>
                    </p>
                    <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                  </div>
                );
              },
            }}
          />
        </div>
        <div class="flex justify-end">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
